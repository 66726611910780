import '../node_modules/photoswipe/dist/photoswipe.css';
import '../node_modules/photoswipe-dynamic-caption-plugin/photoswipe-dynamic-caption-plugin.css';
import '../node_modules/flatpickr/dist/flatpickr.min.css';
import '../node_modules/@fortawesome/fontawesome-free/css/fontawesome.css';
import '../node_modules/@fortawesome/fontawesome-free/css/regular.css';
import '../node_modules/@fortawesome/fontawesome-free/css/solid.css';
import '../node_modules/@fortawesome/fontawesome-free/css/brands.css';
import '../node_modules/swiper/swiper-bundle.css';

import '../sdist/tailwind.css';


import Gumshoe from 'gumshoejs'
import Swiper from 'swiper/bundle';

import PhotoSwipeLightbox from 'photoswipe/dist/photoswipe-lightbox.esm.js';
import PhotoSwipe from 'photoswipe/dist/photoswipe.esm.js';
import PhotoSwipeDynamicCaption from 'photoswipe-dynamic-caption-plugin/photoswipe-dynamic-caption-plugin.esm.js';
import 'swiper/swiper-bundle.css';

import CookiesEuBanner from 'cookies-eu-banner';
// configure Swiper to use modules
import Alpine from 'alpinejs'
import persist from '@alpinejs/persist'

import flatpickr from "flatpickr";
import { German } from "flatpickr/dist/l10n/de";

Alpine.plugin(persist)

var banner = new CookiesEuBanner(function(){
  var _paq = window._paq = window._paq || [];
  /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
  _paq.push(['trackPageView']);
  _paq.push(['enableLinkTracking']);
  (function () {
    var u = "//stats.371stadtmagazin.de/";
    _paq.push(['setTrackerUrl', u + 'matomo.php']);
    _paq.push(['setSiteId', '3']);
    var d = document, g = d.createElement('script'), s = d.getElementsByTagName('script')[0];
    g.type = 'text/javascript'; g.async = true; g.src = u + 'matomo.js'; s.parentNode.insertBefore(g, s);
  })();
});


function initMap(zoom, center){
  map = new google.maps.Map(document.getElementById('map'), {
    zoom: zoom || 11,
    center: center | { lat: 25.684411, lng: -100.317283 }
  });
  var mapReadyEvent = new CustomEvent('map-ready');
  window.dispatchEvent(mapReadyEvent);
}

window.addEventListener('load', () => {

  var HomeCarousel = new Swiper('.home-carousel', {
    spaceBetween: 0,
    preloadImages: false,
    // Enable lazy loading
    lazy: true,
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
    navigation: {
      prevEl: '.swiper-button-prev',
      nextEl: '.swiper-button-next',
    },
    autoplay: {
      delay: 5000,
      disableOnInteraction: true,
    },
    //effect: 'fade',
    speed: 900,
    // fadeEffect: {
    //   crossFade: true
    // },
  });

  flatpickr(".datetimeinput", {
    enableTime: true,
    dateFormat: "d.m.Y H:i:s",
    locale: German,
  });

  if (document.getElementsByClassName('gallery-main').length) {
    var galleryThumbs = {};

    if (document.getElementsByClassName('gallery-thumbs').length) {
      galleryThumbs = {swiper: new Swiper('.gallery-thumbs', {
        spaceBetween: 10,
        slidesPerView: 2,
        freeMode: true,
        navigation: {
          nextEl: '.gallery-thumbs-next',
          prevEl: '.gallery-thumbs-prev',
        },
        loop: true,
        centeredSlides: false,
        watchSlidesVisibility: true,
        watchSlidesProgress: true,
        slideToClickedSlide: true,
        preloadImages: false,
        freeModeSticky: true,
        // Enable lazy loading
        lazy: true,
        breakpoints: {
          // others are 640px, 768px, 1024px, 1280px see tailwind.config.js
          // TODO: import from tailwind
          768: {
            slidesPerView: 2,
            spaceBetween: 20,
            direction: 'vertical',
          },
        }
      })};
    } else if (document.getElementsByClassName('gallery-thumbs-3x').length) {
      galleryThumbs = {swiper: new Swiper('.gallery-thumbs-3x', {
        spaceBetween: 10,
        slidesPerView: 2,
        freeMode: true,
        navigation: {
          nextEl: '.gallery-thumbs-next',
          prevEl: '.gallery-thumbs-prev',
        },
        loop: true,
        centeredSlides: false,
        watchSlidesVisibility: true,
        watchSlidesProgress: true,
        slideToClickedSlide: true,
        preloadImages: false,
        freeModeSticky: true,
        // Enable lazy loading
        lazy: true,
        breakpoints: {
          // others are 640px, 768px, 1024px, 1280px see tailwind.config.js
          // TODO: import from tailwind
          768: {
            slidesPerView: 3,
            spaceBetween: 20,
            direction: 'vertical',
            centeredSlides: true,
          },
        }
      })};
    }

    var galleryTop = new Swiper('.gallery-main', {
      spaceBetween: 0,
      preloadImages: false,
      pagination: {
        el: '.gallery-main-indicator',
        type: 'fraction',
      },
      // Enable lazy loading
      lazy: true,
      thumbs: galleryThumbs,
    });

    const gallerylightbox = new PhotoSwipeLightbox({
      gallerySelector: '.gallery-main',
      childSelector: '.gallery-item',
      pswpModule: PhotoSwipe,

      // Optional padding for images,
      // note that this is an option of PhotoSwipe, not a plugin
      paddingFn: (viewportSize) => {
        return {
          top: 30, bottom: 30, left: 70, right: 70
        }
      },
    });

    const gallerycaptionPlugin = new PhotoSwipeDynamicCaption(gallerylightbox, {
      // Plugins options, for example:
      type: 'auto',
    });

    // make sure you init photoswipe core after plugins are added
    gallerylightbox.init();
  }


});


const lightbox = new PhotoSwipeLightbox({
  gallerySelector: '.gallery-line',
  childSelector: '.gallery-item',
  showHideAnimationType: 'zoom',
  pswpModule: PhotoSwipe,

  // Optional padding for images,
  // note that this is an option of PhotoSwipe, not a plugin
  paddingFn: (viewportSize) => {
    return {
      top: 30, bottom: 30, left: 70, right: 70
    }
  },
});

const captionPlugin = new PhotoSwipeDynamicCaption(lightbox, {
  // Plugins options, for example:
  type: 'auto',
  captionContent: (slide) => {
    let currentSlide = slide.data.element;
    if (currentSlide) {
      let caption = currentSlide.querySelector('.caption');
      if (caption) {
        return caption.innerHTML;
      } else {
        // look for neighboring elements with .extra-caption class
        let next = currentSlide.parentNode.querySelector('.extra-caption');
        if (next) {
          return next.innerHTML;
        }
      }
    }
  }
});

// make sure you init photoswipe core after plugins are added
lightbox.init();

Alpine.data('InitGoogleMaps', function(apiKey, callbackName = 'init') {
  return {
    show_once: false,
    allowed: this.$persist(false).as('external-content-allowed'),
    loaded: false,
    showExternal() { return this.show_once || this.allowed },
    loadGoogleMaps() {
      var d = document, g = d.createElement('script'), s = d.getElementsByTagName('script')[0];
      g.type = 'text/javascript'; g.async = true; g.defer = true; g.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&v=beta&callback=${callbackName}`; s.parentNode.insertBefore(g, s);
    },
    checkAndLoad() {
      if (this.showExternal() && !this.loaded) {
        this.loadGoogleMaps();
        this.loaded = true;
      }
    },
    init() {
      this.checkAndLoad();
      this.$watch('showExternal', () => this.checkAndLoad());
    }
  }
});

Alpine.start()
